.closeButton {
  display: flex;
  justify-content: flex-end;
  background: #f4f4f4;
  padding-top: 5px;
  padding-right: 25px;
  padding-bottom: 5px;
  border-radius: 0px 0px !important;
}
.contentImageModal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 10px 25px 50px 25px;
  background: #f4f4f4;
  border-radius: 0px 0px !important;
}

.styleImage {
  max-width: 90%;
  height: 650px;
  border-radius: 10px;
  transform: none;
  box-shadow: 0 0 14px 0 var(--color-sombra);
}

.styleButton:hover {
  color: rgb(83, 85, 92);
}

.styleButton {
  border-radius: 15px;
  color: rgba(21, 22, 26, 0.24);
  cursor: pointer;
  box-shadow: 0 0 14px 0 rgba(106, 108, 117, 0.24);
  transition: all 0.3s ease-in-out;
}

.styleButton:active {
  opacity: 0.3;
}

.modal-gallery > .modal-content {
  border-radius: 15px !important;
  border: 4px #f4f4f4 solid;
}

.tileStyle {
  width: 99% !important;
  height: 99%;
}

@media (max-width: 800px) {
  .tileStyle {
    height: 99%;
    width: 99% !important;
  }
  .styleImage {
    max-width: 75%;
    height: auto;
  }
}
@media (max-width: 600px) {
  .tileStyle {
    height: 99%;
    width: 99% !important;
  }
  .contentImageModal {
    padding: 10px 25px 25px 25px;
  }
  .styleImage {
    max-width: 70%;
    height: auto;
  }
}
