.contentTileSearch {
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 15px 20px 5px 20px;
  transition: all 0.3s ease-in-out;
  &:hover {
    border-radius: 15px;
    box-shadow: rgba(21, 22, 26, 0.24) 0px 0px 25px 3px;
  }
  &:active {
    opacity: 0.3;
  }
}
