@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.checkout-stepper {
  .stepper {
    overflow: none;
    &__head {
      &--active {
        .stepper__head__index {
          background-color: var(--boton-primario);
        }
      }
      &--disabled {
        .stepper__head__index {
          background-color: #81bbf9;
        }
      }
    }

    @include media-breakpoint-down(md) {
      &__head {
        &__description {
          display: none;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      &__head {
        &__index {
          margin: 0 8px;
        }

        &__title {
          display: none;
        }
      }
    }

    &__action {
      + .stepper__action {
        margin-right: 0;
      }
    }

    &__connector {
      min-width: 26px;
    }
  }
}

.cart-item {
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-down(sm) {
    flex-wrap: nowrap;
  }
  @media (max-width: 409px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  &__resume {
    width: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    .infoQuantyItem {
      width: 35%;
      .dataItem {
        display: flex;
        justify-content: space-between;
        .fieldData {
          font-family: sans-serif;
        }
        .valueData {
          font-family: sans-serif;
          font-weight: bold;
        }
      }
    }
    .infoItem {
      width: 50%;
      display: flex;
      flex-direction: column;
      @include media-breakpoint-down(md) {
        width: 40%;
      }
      @include media-breakpoint-down(sm) {
        margin-top: 1em;
        // width: 100%;
      }
      .dataItem {
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-down(md) {
          flex-wrap: wrap;
          justify-content: center;
        }
        @include media-breakpoint-down(sm) {
          flex-wrap: wrap;
          justify-content: center;
        }

        .fieldData {
          font-family: sans-serif;
        }
        .valueData {
          font-family: sans-serif;
          font-weight: bold;
        }
      }
    }

    .add-remove {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      a {
        svg {
          color: black !important;
          margin: 0;
          width: 28px;
          height: 28px;
        }

        &:hover {
          svg {
            color: var(--boton-primario) !important;
          }
        }
      }

      span {
        padding: 0 0.3em;
      }
    }
  }

  &__details {
    display: flex;
    flex: 1 0 auto;
    max-width: 65%;
    width: auto;
    padding-right: 1em;

    strong {
      display: block;
    }
    div {
      @media (max-width: 409px) {
        text-align: end;
      }
    }

    @include media-breakpoint-down(xs) {
      margin-bottom: 1em;
      padding-right: 0;
    }

    @include media-breakpoint-down(sm) {
      flex: 1 0 100%;
      max-width: 60%;

      img {
        max-width: 36px;
        height: auto;
      }

      strong {
        font-size: 13px;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
      }
    }

    @include media-breakpoint-down(only) {
      max-width: 60%;

      img {
        max-width: 60px;
        height: auto;
      }

      @media (max-width: 409px) {
        max-width: 90%;
      }

      strong {
        font-size: 13px;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
      }
    }
  }

  &__actions {
    min-width: 180px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 0 0 auto;
    max-width: initial;
    width: auto;

    h6 {
      border-bottom: 1px solid var(--color-secundario);
      padding-bottom: 0.2em;
      text-align: right;
    }

    > div {
      display: flex;
      align-items: flex-end;
      @media (min-width: 410px) {
        flex-direction: column;
        justify-content: center;
      }
      &:first-child {
        .icon-md{
          color: var(--boton-primario) !important;
        }
        @media (max-width: 409px) {
          width: 95%;
          display: flex;
          justify-content: space-between;
          .add-remove {
            position: relative;
            top: -7px;
            flex-wrap: nowrap;
          }
        }
      }
      &:last-child {
        margin-top: 0.8em;
        @media (max-width: 409px) {
          width: 35%;
          display: flex;
          justify-content: center;
        }
      }
    }

    @include media-breakpoint-down(xs) {
      max-width: 100%;
      min-width: auto;
    }
    @media (max-width: 409px) {
      width: 100%;
      max-width: 100%;
      align-items: baseline;
      display: flex;
      justify-content: space-between;
    }
  }

  &__delete {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
  }

  + .cart-item {
    border-top: 1px solid var(--color-secundario);
    padding-top: 1em;
    margin-top: 1em;
  }
}

.row .col-md-3 .card-body {
  box-shadow: 0 0 14px 0 var(--color-sombra);
}

.mdc-button--unelevated:not(:disabled) {
  background-color: #0069d9 !important;
}
