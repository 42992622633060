.container-login {
  background-color: #f2f3f4;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
}

.card-login {
  width: 455px;
  margin-top: 65px;
  max-height: 70%;
  margin-bottom: 95px;
}

.avatar-login {
  width: 200px;
  height: 166px;
  border-radius: 50%;
}

.position-eye {
  left: 90%;
  top: 35px;
}
