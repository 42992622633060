
.card {
  border-radius: 0.5rem !important;
  box-shadow: 0 3px 6px rgba($color: black, $alpha: 0.1);
  border: 0 !important;
  .card-title {
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 20px;
    font-family: var(--fuente-primaria);
  }
}
