
.tableLeft {
  border: 1px solid white;
  background: #ebecee !important;
  p {
    color: #000;
    text-align: justify;
    text-transform: capitalize;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-family: sans-serif;
    font-weight: bold;
  }
}
.tableRight {
  border: 1px solid white;
  p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: #343a40;
    font-family: sans-serif;
  }
}

.ean-number {
  color: #1f64bf;
  font-weight: bolder;
}

.autor {
  color: #4886d9;
  font-weight: bolder;
}

.editorial {
  color: #1fa6a6;
  font-weight: bolder;
  font-style: italic;
}

.genres {
  color: #0c59f2;
  font-weight: bolder;
  font-style: italic;
}
