// input files
.upload-container {
  background-color: #00000015;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  border-radius: 15px;
  border: 3px dashed var(--color-primario);
  color: var(--color-primario);
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .upload-content {
    color: var(--color-secundario);
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    svg {
      cursor: pointer;
      padding: 5px;
      z-index: 100000;
    }
  }
}
