.legal {
  margin-bottom: 20px;
  > p,
  li {
    font-size: 1.1rem;
    margin: 30px 0px;
  }
  &:last-of-type {
    margin-bottom: 0px;
  }
  .linkFormat {
    font-weight: 900;
    font-size: 1.2rem;
  }
}

.companyInfo {
  text-align: center;
  margin: 20px auto;
  border: 2px solid #000;
  border-radius: 15px;
  padding: 20px;
}

span {
  margin-bottom: 10px;
}

.claims-form {
  min-width: 50%;
  .form-group {
    margin-bottom: 1.2rem;
  }
  input {
    height: 45px;
    border: solid 1px #a1a1a1;  
  }
}
