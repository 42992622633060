
.tiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1em;
}

.galleryCategories {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 10px;
  box-shadow: 0 0 14px 0 var(--color-sombra);
  border-radius: 25px;
  .titleSelect {
    font-family: sans-serif;
    font-weight: bolder;
    color: #1f64bf;
    margin: 20px;
  }
  .searchGallery {
    margin: 20px;
  }
}

.tile {
  padding: 5px;
  cursor: pointer;
  overflow: hidden;
  height: 255px;
  width: 30%;
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-content: stretch;
  align-items: center;

  img {
    width: 100%;
    border-radius: 15px;
    transition: all 0.3s ease-in-out;
    transition: transform 500ms ease-out;
    box-shadow: 0 0 14px 0 var(--color-sombra);
    // // transition: 0.3s ease-in-out;
    object-fit: cover;

    &:hover {
      border-radius: 15px;
    }
    &:active {
      opacity: 0.3;
    }
  }
}
@media (max-width: 800px) {
  .tiles {
    justify-content: space-between;
    align-content: stretch;
    align-items: center;
  }
  .tile {
    height: 30vw;
    width: 40%;
  }
  .contentTiles {
    flex-wrap: wrap-reverse !important;
  }
}
@media (max-width: 600px) {
  .tiles {
    flex-direction: column;
    align-items: center;
  }
  .tile {
    &:first-child {
      margin-top: 1.5em;
    }
    &:last-child {
      margin-bottom: 1.5em;
    }
    height: 50vw;
    width: 50%;
  }
  .contentTiles {
    flex-wrap: wrap-reverse !important;
  }
}
