.button {
  $p: &;

  color: var(--texto-primario);
  background-color: var(--color-secundario);
  border: medium none;
  display: inline-flex;
  align-items: center;
  min-height: 28px;
  transition: background-color 0.2s ease-in-out;

  &--outline {
    border: 1px solid black;

    &#{$p}--primary {
      color: var(--color-terciario) !important;
      border: 1px solid var(--color-primario);

      &:before,
      &:after {
        background-color: rgba(var(--color-primario), 0.05);
      }

      &:hover {
        background-color: rgba(var(--color-primario), 0.1);
      }
    }

    &-hover {
      &:hover {
        color: var(--texto-secundario);
        background-color: var(--color-primario);
        border: 2px solid var(--texto-secundario);
        outline: none;
      }
    }
  }

  &--icon {
    box-shadow: 0 2px 4px rgba(black, 0.5);
    width: 40px;
    height: 40px;
    border-radius: 20px;
    justify-content: center;
    padding: 0;
  }

  &:not(#{$p}--outline) {
    &#{$p}--primary {
      background-color: var(--color-terciario) !important;
    }
  }

  &--neutral {
    background-color: var(--color-secundario);
    color: var(--color-primario);

    &:hover {
      outline: medium none;
      color: var(--color-secundario);
      background-color: var(--color-primario);
    }
  }

  &--small {
    border-radius: 14px;
    height: 28px;
  }
}

.button-search {
  background-color: #666666 !important;
  width: 40%;
  margin-right: 15px;
  cursor: pointer;
}

.input-search {
  width: 400px !important;
  &:focus {
    border: 1px solid #666666 !important;
    box-shadow: 0 0 0 0.2rem #dee2e6 !important;
  }
}

.page-link {
  color: black !important;
}

li.disabled {
  & a {
    background-color: #dee2e6 !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}
