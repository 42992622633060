.adminAsideRow {
  .card-header {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none !important;
    p {
      font-family: 'Montserrat';
      background: white;
      color: rgba(0, 0, 0, 0.5) !important;
      font-weight: 600;
      font-size: 20px;
    }
  }
  .link-text {
    font-size: 15px;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.5);
    text-align: start !important;
    background-color: white;
    &:hover {
      background-color: rgba(var(--color-primario), 0.1);
      color: black;
    }
  }
}
