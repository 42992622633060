@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.footer {
  background: white;
  width: 100%;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  box-shadow: 0 4px 35px -10px #ced4da;
  margin: 0px;

  .container {
    > div {
      @include media-breakpoint-down(md) {
        margin-top: 1em;
        margin-bottom: 1em;
      }
    }
  }

  .container {
    max-width: 68vw;
  }

  .information {
    margin-bottom: 15px;
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .firstSection {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      padding: 0px;

      .img_text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        height: 100%;
      }

      img {
        max-height: calc(30px + 9vw);
        position: relative;
        top: 0.7vw;
        right: 0px !important;
        margin: auto;

        @include media-breakpoint-down(sm) {
          top: 0px;
          right: 0px;
        }
        @include media-breakpoint-down(lg) {
          top: 1vw;
        }
      }
    }

    .secondSection > p:nth-of-type(1) {
      @include media-breakpoint-down(sm) {
        .row {
          display: flex;
          justify-content: center;
          margin-bottom: 13px;
        }

        div:last-of-type {
          position: relative;
          top: 7px;
          padding: 0px 10px;
          text-align: initial;
        }
      }
    }

    .contSmall > .contact {
      @include media-breakpoint-down(sm) {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }

    .secondSection,
    .thirdSection {
      .table-footer {
        display: flex;
        justify-content: end;

        @include media-breakpoint-down(sm) {
          justify-content: space-evenly;
        }
      }

      @include media-breakpoint-down(md) {
        text-align: center;
      }

      /*.nav-link {
        padding: 0.2rem 0rem;
      }*/

      .nav-link p {
        width: 45px;
        margin: 2px -3px;
        height: 15px;
        position: relative;
        top: -5px;
      }
    }

    .secondSection > div {
      margin: 0px;
      font-size: 13px;
      font-family: sans-serif;
      padding: 0rem 0rem;
    }

    .contact {
      display: flex;
      justify-content: center;

      .contactDetails {
        text-align: center;
        margin: 0.25rem 0 0 0.25rem;
      }
    }

    .secondSection,
    .thirdSection {
      padding: 0px;
      padding-left: 1px;
    }

    .lastSection {
      font-family: sans-serif;
      font-weight: bolder;
      padding: 0px;

      .socialIcons {
        display: flex;
        justify-content: space-evenly;
        margin: auto;
        position: relative;
        top: 5px;
      }
    }
  }

  .bottom-footer {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0 4px 35px -10px #ced4da;

    .copyright {
      width: 100%;
      display: flex;
      font-size: 13px;
      font-family: sans-serif;
      font-weight: bolder;
      justify-content: center;
      align-items: center;
      text-align: center;

      @include media-breakpoint-down(md) {
        margin: 0;
      }

      .linksPrivacity {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      .linksPrivacity,
      .markup {
        @include media-breakpoint-down(md) {
          text-align: center;
        }
      }

      .linksPrivacity > p {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }
}

.text-footer {
  font-family: sans-serif;
  font-size: 14px;
  font-weight: bolder;

  .nav-link {
    padding: 0px !important;
    color: #1f64bf;
  }

  .icon-md {
    color: black;
  }
}

.text-footer:active {
  opacity: 0.3;
  transition: all 0.3s ease-in-out;
}

.title-footer {
  font-family: sans-serif;
  font-weight: bolder;
  text-align: center;

  @include media-breakpoint-down(lg) {
    margin-top: 2em;
  }
}
