.cookies {
  position: fixed;
  bottom: -15px;
  z-index: 200;
}

.cookies-row {
  max-width: 102%;
  p {
    font-weight: bolder;
    color: #fff; /*var(--texto-secundario)*/
  }
  svg {
    font-size: 28px;
  }
  div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      font-weight: bolder;
      height: 60px;
      position: relative;
      left: -15px;
    }
  }
}
